import {useContext} from "react";
import {Container, Grid, Box, Button, Select, MenuItem} from "@mui/material";
import {DataGridPro, GridColDef, GridFilterModel, GridLinkOperator} from "@mui/x-data-grid-pro";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import Page from "../common/Page";
import {
    Company,
    Contractor,
    RoleEnum,
    useCreateLaborContractMutation,
    useGetLaborContractsQuery,
    TaxClassificationEnum,
    IncomeTypeEnum,
    useUpdateLaborContractMutation, Authorization,
} from "../generated/graphql";
import UpdatePopupButton from "../component/UpdatePopupButton";
import {InputType} from "../common/FormRenderer";
import {useCallback, useEffect, useState} from "react";
import NotificationPopup from "../common/NotificationPopup";
import {format} from "date-fns";
import {calculateContractTotals, checkAlert, DataGridLocale, useContractors} from "../common/Utils";
import {useNavigate} from "react-router-dom";
import {useCompany, useUser} from "../auth/Auth";
import {IncomeTypeMap, LaborContractStatusMap, mapToList} from "../common/Constant";
import {find, first, pick, filter} from "lodash";
import ConfirmButton from "../component/ConfirmButton";
import DownloadLaborContractButton from "./DownloadLaborContractButton";
import FeesDirectionButton from "./FeesDirectionButton";
import {CompanyContext} from "../routes";
import theme from "../theme/Theme";

enum TabFilter {
    All,
    Done,
    Progressing,
}

export default () => {
    const [createLaborContract] = useCreateLaborContractMutation();
    const me = useUser();
    // const [company, setCompany] = useState<Company | undefined>();
    const [contractor, setContractor] = useState<Contractor | undefined>();
    const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>();
    const [filterLaborContracts, setFilterLaborContracts] = useState<any>([]);
    const {loading, data, error} = useGetLaborContractsQuery();
    const [updateLaborContract] = useUpdateLaborContractMutation();
    const [tabIndex, setTabIndex] = useState(TabFilter.All);
    const [filterModel, setFilterModel] = useState<any>({items: []});

    const company = useContext(CompanyContext);
    checkAlert(company, Authorization.LaborContract);

    useEffect(()=>{
        setFilterModel((tabIndex==TabFilter.All) ?
            {items:[]} : {items:[{columnField: "status", value: "finished",
                    operatorValue: (tabIndex==TabFilter.Done ? "is" : "not")}]});
    }, [tabIndex]);
    // const filterModel = (tabIndex==TabFilter.All) ?
    //         {items:[]} : {items:[{columnField: "status", value: "draft",
    //                 operatorValue: (tabIndex==TabFilter.Done ? "is" : "not")}]}

    const onCreate = useCallback(
        async (laborContract, {resetForm}, setOpen) => {
            if (company) {
                laborContract.companyId = company.id
            }
            // if (contractor) {
            //     laborContract.contractorName = contractor.name
            //     laborContract.contractorAddress = contractor.address
            //     laborContract.contractorEmail = contractor.email
            //     laborContract.contractorPhone = contractor.phone
            //     laborContract.contractorIdNumber = contractor.idNumber
            //     laborContract.contractorTaxClassification = contractor.taxClassification
            //     laborContract.contractorContactAddress = contractor.contactAddress
            //     laborContract.contractorBankCode = contractor.bankCode
            //     laborContract.contractorBankAccountNumber = contractor.bankAccountNumber
            //     laborContract.contractorNationality = contractor.nationality
            //     laborContract.contractorNoInsurance = contractor.noInsurance
            // }

            try {
                const result = await createLaborContract({
                    variables: {
                        laborContract: pick(laborContract, [
                            "name",
                            "companyId",
                            "contractContent",
                            "date",
                            "payDate",
                            // "contractorName",
                            // "contractorAddress",
                            // "contractorEmail",
                            // "contractorPhone",
                            // "contractorIdNumber",
                            // "contractorTaxClassification",
                            // "contractorContactAddress",
                            // "contractorBankCode",
                            // "contractorBankAccountNumber",
                            // "contractorNationality",
                            // "contractorNoInsurance",
                            "taxPaid",
                            "contractorId",
                        ]),
                    },
                    refetchQueries: ["getLaborContracts"],
                });
                NotificationPopup.success(`新增成功`);
                window.open(`/laborContract/${result?.data?.createLaborContract.id}`, '_blank');
            } catch (e: any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createLaborContract, company, contractor]
    )
    const onUpdateTax = useCallback(async (id, taxPaid) => {
        await updateLaborContract({
            variables: {
                id: id,
                laborContract: {taxPaid: taxPaid},
            },
            refetchQueries: ["getLaborContracts"]
        });
    },[])
    // useEffect(() => {
    //     setCompany(first(me?.companies))
    // }, [me])
    useEffect(() => {
        if (company) {
            setFilterLaborContracts(filter(data?.laborContracts, contract => contract.company.id == company.id))
        }
    }, [data, company])
    const columns: GridColDef[] = [{
        field: "action", headerName: "動作", width: 80,
        renderCell: (params) => {
            return <>
                <Button
                    variant={"contained"}
                    color={"primary"} onClick={() => {
                    window.open(`/laborContract/${params.row.id}`, '_blank');
                }}>
                    查看
                </Button>
            </>
        }
    },
        {field: "tax", headerName: "繳稅狀態", width: 100,
            valueGetter: params => {
                const calculatedAmount = calculateContractTotals(
                    params.row.amount,
                    params.row.contractorTaxClassification as TaxClassificationEnum,
                    params.row.incomeType as IncomeTypeEnum,
                    params.row.contractorNoInsurance,
                )
                if (calculatedAmount.taxAmount || calculatedAmount.insuranceAmount) {
                    if (params.row.taxPaid)
                        return "取消繳納";
                    else
                        return "稅費繳納";
                }
                else {
                    return "無需繳納";
                }
            },
            renderCell: params => {
                const calculatedAmount = calculateContractTotals(
                    params.row.amount,
                    params.row.contractorTaxClassification as TaxClassificationEnum,
                    params.row.incomeType as IncomeTypeEnum,
                    params.row.contractorNoInsurance,
                )
                if (calculatedAmount.taxAmount || calculatedAmount.insuranceAmount) {
                    if (params.row.taxPaid)
                        return <ConfirmButton
                            buttonProps={{color: "secondary"}}
                            message={"確定要取消繳納？"}
                            onConfirm={async ()=>{
                                await onUpdateTax(params.row.id, false);
                            }}>取消繳納</ConfirmButton>
                    else
                        return <ConfirmButton
                            buttonProps={{color: "success"}}
                            message={"確定已經繳納？"}
                            onConfirm={async ()=>{
                                await onUpdateTax(params.row.id, true);
                            }}>稅費繳納</ConfirmButton>
                }
                else {
                    return <ConfirmButton onConfirm={null} disabled={true} buttonProps={{color: "info"}}>無需繳納</ConfirmButton>
                }

            }},
        {field: "id", headerName: "ID", width: 100},
        {field: "name", headerName: "專案名稱", width: 150},
        {
            field: "status", headerName: "狀態", width: 100, type: "singleSelect",
            valueFormatter: params => LaborContractStatusMap[(params as any).value],
            valueOptions: mapToList(LaborContractStatusMap),
        },
        {field: "contractContent", headerName: "勞務內容", width: 150},
        {
            field: "payDate", headerName: "支付日期", valueGetter:
                params => format((params as any).value ? new Date((params as any).value) : new Date(), "yyyy-MM-dd")
        },
        {field: "amount", type: "number", headerName: "支領金額", valueFormatter: (params) => params?.value?.toLocaleString()},
        {field: "taxAmount", type:"number", headerName: "代扣所得稅", valueGetter: (params) =>
            {
                const calculatedAmount = calculateContractTotals(
                    params.row.amount,
                    params.row.contractorTaxClassification as TaxClassificationEnum,
                    params.row.incomeType as IncomeTypeEnum,
                    params.row.contractorNoInsurance,
                )
                return calculatedAmount.taxAmount;
            }},
        {field: "insuranceAmount", type:"number", headerName: "二代健保", valueGetter: (params) =>
            {
                const calculatedAmount = calculateContractTotals(
                    params.row.amount,
                    params.row.contractorTaxClassification as TaxClassificationEnum,
                    params.row.incomeType as IncomeTypeEnum,
                    params.row.contractorNoInsurance,
                )
                return calculatedAmount.insuranceAmount;
            }},
        {field: "contractorName", headerName: "姓名"},
        {field: "incomeType", headerName: "所得類別", valueGetter: params => IncomeTypeMap[(params as any).value]},

    ];

    return (
        <Page title={"labor contract list page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                    <Grid item>
                        <UpdatePopupButton
                            title={"新增勞報單"}
                            columns={[
                                {
                                    name: "companyId",
                                    label: "公司",
                                    value: company?.id,
                                    type: InputType.select,
                                    // value: me?.companies ? (me?.companies as any)[0].id : undefined,
                                    options: me?.companies?.map((company) => {
                                        return {value: company?.id, label: company?.name}
                                    }),
                                },
                                {
                                    name: "contractorId",
                                    label: "廠商(可稍後填寫)",
                                    type: InputType.select,
                                    options: company?.contractors ? company?.contractors?.map((contractor) => {
                                        return {value: contractor?.id, label: contractor?.name}
                                    }) : [],
                                },
                                {
                                    name: "name",
                                    label: "專案名稱",
                                    required: true,
                                },
                                {
                                    name: "contractContent",
                                    label: "勞務內容",
                                },
                                {
                                    name: "date",
                                    label: "填表日期",
                                    type: InputType.date,
                                    required: true,
                                },
                                {
                                    name: "payDate",
                                    label: "支付日期",
                                    type: InputType.date,
                                    required: true,
                                },
                            ]}
                            onSubmit={onCreate}
                            submitTitle={"儲存"}
                        />
                    </Grid>

                    <Grid item>
                        <DownloadLaborContractButton company={company}/>
                    </Grid>

                    <Grid item style={{flex: 1}}>
                        <FeesDirectionButton/>
                    </Grid>
                </Grid>
                <DefaultQueryHandler error={error} loading={loading}>
                    <Box
                        width={"100%"}
                        style={{marginTop: "30px", height: "100vh"}}
                    >
                        <Button style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            backgroundColor: tabIndex==TabFilter.Done ? theme.palette.primary.main : "white",
                            color: tabIndex==TabFilter.Done ? "white" : theme.palette.primary.main,
                            borderBottomLeftRadius: "unset",
                            borderBottomRightRadius: "unset",
                            borderTopRightRadius: "unset",
                            borderBottom: "unset",
                            borderRight: "unset"}} onClick={()=>{
                                setTabIndex(tabIndex==TabFilter.Done ? TabFilter.All : TabFilter.Done);
                        }}>已完成</Button>
                        <Button style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            backgroundColor: tabIndex==TabFilter.Progressing ? theme.palette.primary.main : "white",
                            color: tabIndex==TabFilter.Progressing ? "white" : theme.palette.primary.main,
                            borderBottomLeftRadius: "unset",
                            borderBottomRightRadius: "unset",
                            borderTopLeftRadius: "unset",
                            borderBottom: "unset"}} onClick={()=>{
                            setTabIndex(tabIndex==TabFilter.Progressing ? TabFilter.All : TabFilter.Progressing);
                        }}>未完成</Button>
                        <DataGridPro
                            filterModel={filterModel}
                            onFilterModelChange={(filterModel) => {
                                setFilterModel(filterModel);
                            }}
                            style={{borderTopLeftRadius: "unset"}}
                            localeText={DataGridLocale}
                            autoHeight={true}
                            loading={loading}
                            rows={filterLaborContracts}
                            columns={columns}
                        />
                    </Box>
                </DefaultQueryHandler>
            </Container>
        </Page>
    );
};
