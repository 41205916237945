import {Container, Box} from "@mui/material";
import {DataGridPro, GridColDef, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid-pro";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import Page from "../common/Page";
import {
    useCreateContractorMutation,
    useRemoveContractorMutation, useUpdateContractorMutation, useGetContractorsQuery
} from "../generated/graphql";
import ConfirmButton from "../component/ConfirmButton";
import {InputType} from "../common/FormRenderer";
import UpdatePopupButton from "../component/UpdatePopupButton";
import {useCallback, useContext, useState} from "react";
import NotificationPopup from "../common/NotificationPopup";
import {omit} from "lodash";
import {useUser} from "../auth/Auth";
import {DataGridLocale} from "../common/Utils";
import UpdateContractorButton from "./UpdateContractorButton";
import {CompanyContext} from "../routes";

export default () => {
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    const [createContractor] = useCreateContractorMutation();
    const [updateContractor] = useUpdateContractorMutation();
    const [removeContractor] = useRemoveContractorMutation();
    const company = useContext(CompanyContext);
    const getCallback = (mutation) => {
        return async (contractor, {resetForm}, setOpen) => {
            try {
                await mutation(contractor);
                NotificationPopup.success(`新增成功`);
            } catch (e: any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        }
    }
    const onRemove = (id) => (
        removeContractor({
            variables: {
                id: id,
            },
            refetchQueries: ["getContractors"]
        })
    );
    const onCreate = useCallback(
        getCallback((contractor) => {
            return createContractor({
                variables: {
                    contractor: contractor,
                },
                refetchQueries: ["getContractors"],
            })
        }),
        [createContractor],
    )
    const onUpdate = useCallback(
        async (contractor, {resetForm}, setOpen) => {
            console.log(contractor);
            try {
                await updateContractor({
                    variables: {
                        contractor: omit(contractor, "id"),
                        id: contractor.id,
                    },
                    refetchQueries: ["getContractors"],
                });
                NotificationPopup.success(`修改完成`);
            } catch (e: any) {
                NotificationPopup.error(`修改使用者發生問題： ${e.message}`);
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateContractor]
    );

    const mutationColumns = (params?: GridRenderCellParams | undefined) => {
        const columns: any = []
        if (params) {
            columns.push({
                name: "id",
                value: params?.row.id,
                type: InputType.hidden,
            });
        }
        else {
            columns.push({
                name: "companyId",
                label: "公司",
                type: InputType.hidden,
                value: company?.id,
            });
        }
        return columns.concat([
            {
                name: "name",
                label: "姓名",
                value: params?.row.name,
            },
            {
                name: "email",
                label: "Email",
                value: params?.row.email,
            },
            {
                name: "address",
                label: "地址",
                value: params?.row.address,
            },
            {
                name: "phone",
                label: "電話",
                value: params?.row.phone,
            },
            {
                name: "idNumber",
                label: "身分證字號",
                value: params?.row.idNumber,
            },
        ])
    };
    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 100},
        {field: "name", headerName: "姓名", width: 150},
        {field: "phone", headerName: "電話", width: 150},
        {field: "idNumber", headerName: "身分證字號", width: 150},
        {field: "email", headerName: "Email", width: 200},
        // {field: "company", headerName: "公司", width: 200, valueGetter: (params) => params.row.company.name},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) => {
                return <>
                    <UpdateContractorButton
                        contractor={params.row}
                    />&nbsp;
                    <ConfirmButton onConfirm={() => {
                        onRemove(params.row.id)
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ];

    const {loading, data, error} = useGetContractorsQuery();
    return (
        <Page title={"user page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <UpdatePopupButton
                    title={"建立廠商"}
                    columns={mutationColumns()}
                    onSubmit={onCreate}
                    submitTitle={"儲存"}
                />
                <DefaultQueryHandler error={error} loading={loading}>
                    <Box
                        width={"100%"}
                        style={{marginTop: "30px", height: "100vh"}}
                    >
                        <DataGridPro
                            localeText={DataGridLocale}
                            autoHeight={true}
                            loading={loading}
                            rows={data?.contractors || []}
                            columns={columns}
                            sortModel={sortModel}
                            onSortModelChange={(sortModel)=> {
                                setSortModel(sortModel);
                            }}
                        />
                    </Box>
                </DefaultQueryHandler>
            </Container>
        </Page>
    );
};
